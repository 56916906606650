@tailwind base;

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/montserrat/montserrat-v14-latin-regular.eot');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('../webfonts/montserrat/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/montserrat/montserrat-v14-latin-regular.woff2') format('woff2'),
         url('../webfonts/montserrat/montserrat-v14-latin-regular.woff') format('woff'),
         url('../webfonts/montserrat/montserrat-v14-latin-regular.ttf') format('truetype'),
         url('../webfonts/montserrat/montserrat-v14-latin-regular.svg#Montserrat') format('svg');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../webfonts/montserrat/montserrat-v14-latin-500.eot');
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
         url('../webfonts/montserrat/montserrat-v14-latin-500.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/montserrat/montserrat-v14-latin-500.woff2') format('woff2'),
         url('../webfonts/montserrat/montserrat-v14-latin-500.woff') format('woff'), 
         url('../webfonts/montserrat/montserrat-v14-latin-500.ttf') format('truetype'), 
         url('../webfonts/montserrat/montserrat-v14-latin-500.svg#Montserrat') format('svg');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../webfonts/montserrat/montserrat-v14-latin-600.eot'); 
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
         url('../webfonts/montserrat/montserrat-v14-latin-600.eot?#iefix') format('embedded-opentype'), 
         url('../webfonts/montserrat/montserrat-v14-latin-600.woff2') format('woff2'),
         url('../webfonts/montserrat/montserrat-v14-latin-600.woff') format('woff'),
         url('../webfonts/montserrat/montserrat-v14-latin-600.ttf') format('truetype'),
         url('../webfonts/montserrat/montserrat-v14-latin-600.svg#Montserrat') format('svg');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/montserrat/montserrat-v14-latin-700.eot');
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
         url('../webfonts/montserrat/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/montserrat/montserrat-v14-latin-700.woff2') format('woff2'),
         url('../webfonts/montserrat/montserrat-v14-latin-700.woff') format('woff'),
         url('../webfonts/montserrat/montserrat-v14-latin-700.ttf') format('truetype'),
         url('../webfonts/montserrat/montserrat-v14-latin-700.svg#Montserrat') format('svg');
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../webfonts/montserrat/montserrat-v14-latin-800.eot');
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
         url('../webfonts/montserrat/montserrat-v14-latin-800.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/montserrat/montserrat-v14-latin-800.woff2') format('woff2'),
         url('../webfonts/montserrat/montserrat-v14-latin-800.woff') format('woff'),
         url('../webfonts/montserrat/montserrat-v14-latin-800.ttf') format('truetype'),
         url('../webfonts/montserrat/montserrat-v14-latin-800.svg#Montserrat') format('svg');
  }
  
  @font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 300;
    src: url('../webfonts/maitree/maitree-v4-latin-300.eot');
    src: local('Maitree Light'), local('Maitree-Light'),
         url('../webfonts/maitree/maitree-v4-latin-300.eot?#iefix') format('embedded-opentype'), 
         url('../webfonts/maitree/maitree-v4-latin-300.woff2') format('woff2'), 
         url('../webfonts/maitree/maitree-v4-latin-300.woff') format('woff'),
         url('../webfonts/maitree/maitree-v4-latin-300.ttf') format('truetype'),
         url('../webfonts/maitree/maitree-v4-latin-300.svg#Maitree') format('svg');
  }
  
  @font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/maitree/maitree-v4-latin-regular.eot');
    src: local('Maitree'),
         url('../webfonts/maitree/maitree-v4-latin-regular.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/maitree/maitree-v4-latin-regular.woff2') format('woff2'),
         url('../webfonts/maitree/maitree-v4-latin-regular.woff') format('woff'),
         url('../webfonts/maitree/maitree-v4-latin-regular.ttf') format('truetype'),
         url('../webfonts/maitree/maitree-v4-latin-regular.svg#Maitree') format('svg');
  }
  
  @font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 500;
    src: url('../webfonts/maitree/maitree-v4-latin-500.eot');
    src: local('Maitree Medium'), local('Maitree-Medium'),
         url('../webfonts/maitree/maitree-v4-latin-500.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/maitree/maitree-v4-latin-500.woff2') format('woff2'),
         url('../webfonts/maitree/maitree-v4-latin-500.woff') format('woff'),
         url('../webfonts/maitree/maitree-v4-latin-500.ttf') format('truetype'),
         url('../webfonts/maitree/maitree-v4-latin-500.svg#Maitree') format('svg');
  }
  
  @font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 600;
    src: url('../webfonts/maitree/maitree-v4-latin-600.eot');
    src: local('Maitree SemiBold'), local('Maitree-SemiBold'),
         url('../webfonts/maitree/maitree-v4-latin-600.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/maitree/maitree-v4-latin-600.woff2') format('woff2'),
         url('../webfonts/maitree/maitree-v4-latin-600.woff') format('woff'),
         url('../webfonts/maitree/maitree-v4-latin-600.ttf') format('truetype'),
         url('../webfonts/maitree/maitree-v4-latin-600.svg#Maitree') format('svg');
  }
  
  @font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/maitree/maitree-v4-latin-700.eot');
    src: local('Maitree Bold'), local('Maitree-Bold'),
         url('../webfonts/maitree/maitree-v4-latin-700.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/maitree/maitree-v4-latin-700.woff2') format('woff2'),
         url('../webfonts/maitree/maitree-v4-latin-700.woff') format('woff'),
         url('../webfonts/maitree/maitree-v4-latin-700.ttf') format('truetype'),
         url('../webfonts/maitree/maitree-v4-latin-700.svg#Maitree') format('svg');
  }
  
  p, ul, ol{
    @apply font-serif text-gray-900;
  }

  ul{
    @apply list-disc ml-3 pl-3;
  }

  @layer base {
    ul, ol {
      list-style: revert;
      margin-top:1rem;
      margin-bottom:1rem;
      padding-left: 20px;
    }
  }

  .button-red{
    @apply py-2 px-4 bg-red-900 font-semibold text-white text-center inline-block;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .button-red:hover{
    @apply bg-red-950;
  }

  .button-pag{
    @apply bg-gray-200 text-blue-900 font-semibold p-2;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .button-pag:hover{
    @apply bg-gray-300;
  }

  .form-control{
    @apply border py-2 px-3 text-gray-900 bg-white;
  }
  .form-control:focus{
    @apply outline-none focus:border-blue-200 focus:ring-1 focus:ring-blue-200;
  }

  .register th{
    @apply px-4 py-3 bg-gray-200 font-semibold text-blue-900 text-left;
  }
  .register tbody tr:nth-child(odd) {
    @apply bg-gray-100;
  }
  .register td{
    @apply px-4 py-3 text-blue-900 text-left;
  }

  .report-table th{
    @apply p-1 bg-blue-900 text-white font-normal font-serif border-white border;
  }

  .report-table td{
    @apply p-1 font-serif align-text-top border;
  }

  .verification-table th{
    @apply p-1 bg-gray-100 font-normal font-serif text-left border align-top;
  }

  .verification-table td{
    @apply p-1 font-serif text-left align-text-top border;
  }

  .table-report th{
    @apply px-4 py-3 text-left border;
  }
  .table-report td{
    @apply px-4 py-3 text-blue-900 text-left border;
  }

  .table-finding th{
    @apply px-4 py-3 text-left border;
  }
  .table-finding td{
    @apply px-4 py-3 text-blue-900 text-left border;
  }

  .wide-table{
    margin-left:-314px;
    padding-left: 346px!important;
  }
  @media(max-width:1198px){
    .wide-table{
      margin-left: -83px;
      padding-left: 115px!important;
    }
  }
  @media(max-width:990px){
    .wide-table{
      margin-left:0;
      padding-left:32px!important;
    }
  }

  /* multi select input */
  .react-select__control{
    border-radius: 0!important;
  }
  .react-select__placeholder{
    color:#333!important;
  }

  /* datepicker container */
  .react-datepicker-wrapper{
    display: block!important;
  }

  /* dropdowns */
  .rc-dropdown-menu > .rc-dropdown-menu-item {
    padding: 2px 10px 2px 10px!important;
  }

  #settings-cog-menu{
    padding:10px 0;
  }
  #settings-cog-menu.rc-dropdown-menu > .rc-dropdown-menu-item:hover, #settings-cog-menu.rc-dropdown-menu > .rc-dropdown-menu-item-selected {
    background-color: #002a4e!important;
    color:#fff!important;
  }.
  #settings-cog-menu.rc-dropdown-menu > .rc-dropdown-menu-item-selected::after {
    display:none!important;
  }

  .table-dd-menu{
    margin-top: -3px!important;
    padding:10px 0!important;
  }
  .table-dd-menu.rc-dropdown-menu > .rc-dropdown-menu-item-selected::after {
    display:none!important;
  }

  #main-nav-dd{
    margin-left:0!important;
    background-color: #fff!important;
    border: 1px solid #ccc;
  }
  .rc-menu.rc-menu-sub.rc-menu-vertical, .rc-menu.rc-menu-sub.rc-menu-vertical-right{
    max-height:400px;
    overflow-y: auto;
    overflow-x: visible;
  }
  .rc-menu-submenu ul.rc-menu.rc-menu-sub.rc-menu-vertical, .rc-menu-submenu ul.rc-menu.rc-menu-sub.rc-menu-vertical-right{
    margin-left:0!important;
  }
  .rc-menu-horizontal > .rc-menu-submenu, .rc-menu-horizontal > .rc-menu-item, .rc-menu-horizontal > .rc-menu-submenu-active{
    border-bottom: none!important;
  }
  .rc-menu-submenu-popup {
    z-index:20;
  }

  /* verification calander */
  .rbc-toolbar-label{
    @apply font-semibold text-xl text-blue-900 text-left;
  }
  .rbc-toolbar .rbc-btn-group button{
    @apply bg-blue-900 font-semibold text-white;
  }
  .rbc-toolbar .rbc-btn-group button:hover{
    @apply bg-blue-700 font-semibold text-white;
  }
  .rbc-toolbar .rbc-btn-group button.rbc-active:hover{
    @apply bg-blue-700 font-semibold text-white;
  }
  .rbc-toolbar .rbc-btn-group button.rbc-active{
    @apply bg-blue-700 font-semibold text-white;
  }
  .rbc-toolbar .rbc-btn-group button.rbc-active:focus{
    @apply bg-blue-700 font-semibold text-white;
  }

  .rbc-off-range {
    color: #626366!important;
  }

  /* CHECKBOX TOGGLE SWITCH */
  .toggle-checkbox:checked {
    @apply: right-0 border-green-900;
    right: 0;
    border-color: #4CA342;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-900;
    background-color: #4CA342;
  }

  .toggle-checkbox:disabled {
    @apply: border-gray-400;
    border-color: #B1B3B5;
  }
  .toggle-checkbox:disabled + .toggle-label {
    @apply: bg-gray-400;
    background-color: #B1B3B5;
  }

  .toggle-checkbox-stats:checked {
    @apply: right-0 border-red-900;
    right: 0;
    border-color: #DA1F33;
  }
  .toggle-checkbox-stats:checked + .toggle-label {
    @apply: bg-red-900;
    background-color: #DA1F33;
  }

  /* TINYMCE TOOLBAR */
  .tox .tox-editor-header {
    z-index: 0!important;
  }

  /* venn diagram responsive helpers & tooltip */
  .svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 65%;
    vertical-align: top;
    overflow: hidden;
  }
  .svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
  }
  .venntooltip {
    position: absolute;
    text-align: center;
    width: auto;
    height: 28px;
    background: #333;
    color: #ddd;
    padding: 2px 6px 2px 6px;
    border: 0px;
    border-radius: 8px;
    opacity: 0;
  }

  .flex-center {
      justify-content: center;
      align-items: center;
  }

  .breadcrumb li:first-child {
      padding-left: 0
  }

@tailwind components;

@tailwind utilities;